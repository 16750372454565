import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Home.module.scss';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {apiCall} from "../helpers/networkHelper";
import {MASTER_PAGE_HOME} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {empty} from "../helpers/generalHelper";
import ModuleNewsletter from "./ModuleNewsletter";
import {ModalContent} from "./atoms/Modal";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homeData: null,
            categories: null,
            imageSliders: null,
            mobileMenuOpened: false,
            popUpOpened: false,
            generalSettings: null,
            hashtagImages: null,
            hashtagSectionTitle: null,
            hashtagSectionDesc: null
        }
    }

    componentDidMount() {
        document.getElementById("loader-wrapper").style = "display: flex";
        document.getElementById("loader").style = "display: block";

        apiCall(
            MASTER_PAGE_HOME,
            'get',
            null,
            res => {
                this.setState({
                    homeData: res.data.home_data,
                    categories: res.data.categories,
                    imageSliders: res.data.image_sliders,
                    generalSettings: res.data.general_settings,
                    hashtagImages: res.data.hashtag_images,
                    hashtagSectionTitle: res.data.hashtag_section_title,
                    hashtagSectionDesc: res.data.hashtag_section_desc
                }, () => {
                    document.getElementById("loader-wrapper").style = "display: none";

                    setTimeout(() => {
                        this.setState({popUpOpened: !empty(res.data.general_settings.INTERSTITIAL_IMAGE_DESKTOP_CI)})
                    }, 2000);
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                // this.hideLoader();
            }
        );
        // setTimeout(function () {
        //     document.getElementById("loader-wrapper").style = "display: none";
        // }, 2000);
    }

    render() {
        const {
            homeData,
            imageSliders,
            popUpOpened,
            generalSettings,
            hashtagImages,
            hashtagSectionTitle,
            hashtagSectionDesc
        } = this.state;
        const sliderSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            swipe: false,
            pauseOnHover: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 8000,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description"
                          content="Inspired by an Island in Rio de Janeiro, Brazil. COCONUT ISLAND rapidly developing into a premium brand t-shirt in Indonesia."/>
                </Helmet>

                {popUpOpened && (
                    <ModalContent onClose={() => {
                        this.setState({popUpOpened: false})
                    }}>
                        <img style={{maxWidth: '100%'}} src={generalSettings.INTERSTITIAL_IMAGE_DESKTOP_CI}
                             alt={'Popup Image'}/>
                    </ModalContent>
                )}

                {!empty(imageSliders) && (
                    <>
                        <section className={styles.sliderSection}>
                            <Slider {...sliderSettings} className={styles.slider}>
                                {imageSliders.map(image => {
                                    if (image.slider_type === 'IMAGE') {
                                        return (
                                            <div key={`DesktopSlider${image.id}`} className="item image">
                                                <a href={image.href_url}
                                                   target={image.open_new_tab === 1 ? '_blank' : '_self'}>
                                                    <img src={image.media_url} alt="Slider Image"/>
                                                </a>
                                            </div>
                                        );
                                    }

                                    if (image.slider_type === 'YOUTUBE') {
                                        return (
                                            <div key={`DesktopSlider${image.id}`} className="item youtube">
                                                <iframe className="embed-player slide-media"
                                                        src={`https://www.youtube.com/embed/${image.youtube_video_id}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=${image.youtube_video_id}&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0`}
                                                        frameBorder="0"
                                                        allowFullScreen
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </Slider>
                        </section>

                        <section className={styles.sliderSectionMobile}>
                            <Slider {...sliderSettings} className={styles.slider}>
                                {imageSliders
                                    .filter(image => image.slider_type === 'IMAGE')
                                    .map(image => (
                                        <div key={`MobileSlider${image.id}`} className="item image">
                                            <a href={image.href_url}
                                               target={image.open_new_tab === 1 ? '_blank' : '_self'}>
                                                <img src={image.media_url_mobile} alt="Slider Image"/>
                                            </a>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </section>
                    </>
                )}

                {!empty(generalSettings?.HOME_VIDEO_CI) && (
                    <section style={{marginBottom: '50px', paddingTop: '0'}}>
                        <video style={{width: '100%'}} autoPlay={true} loop={true} muted={true}>
                            <source src={generalSettings.HOME_VIDEO_CI} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </section>
                )}

                <div style={{position: 'sticky', top: 0}}>
                    <section className={styles.row1}>
                        <div className={styles.col1}>
                            <img src={homeData?.HOME_SECTION_1_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_1_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_1_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_1_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_1_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_1_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_1_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_1_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                    </section>

                    <section className={styles.row2}>
                        <div className={styles.col1}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_2_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_2_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_2_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_2_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_2_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_2_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_2_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                        <div className={styles.col2}>
                            <img src={homeData?.HOME_SECTION_2_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                    </section>

                    <section className={styles.row1}>
                        <div className={styles.col1}>
                            <img src={homeData?.HOME_SECTION_3_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                        <div className={styles.col2} style={{textAlign: 'left'}}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_3_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_3_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_3_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_3_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_3_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_3_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_3_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                    </section>

                    {!empty(hashtagImages) && (
                        <section className={styles.hashtagSection}>
                            <h2><Link to='/hashtag'>{hashtagSectionTitle}</Link></h2>
                            <p>
                                {hashtagSectionDesc}
                            </p>
                            <div className={styles.hashtagPhotos}>
                                {hashtagImages.map((item) => (
                                    <div className={styles.hashtagPhoto}>
                                        <a href={item.ig_url} target="_blank"
                                           rel="noopener noreferrer">
                                            <img
                                                src={item.image_url}
                                                alt="HashTag Photo"/>
                                            {/*<div className={styles.hashtagPhotoOverlay}>*/}
                                            {/*    <div className={styles.hashtagPhotoText}>*/}
                                            {/*        @hammerclothing.id*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}

                    <ModuleNewsletter/>

                    {/*<FooterOne logoName={'logo.png'}/>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Home;